import React, { useState, useEffect } from 'react';
import withAuth from '../../withAuth';
import { useNavigate, 
    // useLocation, 
useParams } from 'react-router-dom';
import { createAction, getAction, editAction, getActionsTemplates } from '../../api';
import { Form, Input, Button, Card, notification, Space, Row, Col, Select } from 'antd';
import { DeleteOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';

const CreateAction = () => {
    const navigate = useNavigate();
    // const location = useLocation();
    const [keywords, setKeywords] = useState([{ value: null }]);
    const { assistantId } = useParams();
    const [action, setAction] = useState(null);
    // const path = location.pathname;
    const [form] = Form.useForm();
    const [actionsTemplates, setActionsTemplates] = useState([]);
    const [selectedActionType, setSelectedActionType] = useState('Email');
    // const newPath = path.replace('/actions/create', '');

    const { id } = useParams();

    useEffect(() => {
        if (id === 'create') {
            form.resetFields();
            form.setFieldsValue({ actionType: 'Email' });
            getActionsTemplates()
            .then(response => {
                setActionsTemplates(response.data);
                // console.log(response.data)
            })
            .catch(error => {
                console.error('Error:', error);
            });
        } else {
            // Load the existing configuration from the database
            getAction(id)
                .then(response => {
                    setSelectedActionType(response.data.actionType);
                    setAction(response.data);
                    setKeywords(response.data.keywords);
                    form.setFieldsValue(response.data);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, [form, id]); // Empty array means this effect runs once on component mount


    const handleAdd = (values) => {
        // const { name, trigger, jsonObject, webhookURL } = values;
        const data = values
        data.keywords = keywords
        data.assistantId = assistantId
        // console.log(data)
        createAction(data)
            .then(response => {
                // console.log(response)
                // navigate(`/assistants/${assistantId}/actions/${response.data.actionId}`)

                navigate(`/assistants/${assistantId}`)
                // navigate(newPath);
            })
            .catch(error => {
                console.log(error);
                notification.error({
                    message: 'Create Action Failed',
                    description: `There was an error while creating the action. Please try again. ${error.response.data.message}`,
                });
            });
    };

    const handleUpdate = (values) => {
        const { name, trigger, jsonObject, requestType, webhookURL, to, subject } = values;
        editAction(id, name, trigger, keywords, jsonObject, requestType, webhookURL, to, subject, action.assistantId)
            .then(response => {
                navigate(`/assistants/${assistantId}`)
                // navigate(newPath);
            })
            .catch(error => {
                console.log(error);
                notification.error({
                    message: 'Edit Action Failed',
                    description: 'There was an error while editing the action. Please try again.',
                });
            });
    };

    // const handleCancel = () => {
    //     navigate(newPath);
    // };

    const handleAddKeyWord = () => {
        const newInputs = [...keywords, { value: null }];
        setKeywords(newInputs);
    }
    
    const handleRemoveKeyWord = (i) => {
        const newInputs = [...keywords];
        newInputs.splice(i, 1);
        setKeywords(newInputs);
    }
    
    const handleChangeKeyWord = (i, event) => {
        const newInputs = [...keywords];
        newInputs[i].value = event.target.value;
        setKeywords(newInputs);
    }

    return (
        <div style={{ padding: '50px' }}>
            <Button type="link" onClick={() => navigate(`/assistants/${assistantId}`)} style={{ marginBottom: '0px' }}>
                <ArrowLeftOutlined /> Go Back
            </Button>    
            <h2 style={{ textAlign: 'left' }}>
                {id === 'create' ? 'Create a Custom Action' : 'Update a Custom Action'}
            </h2>          
            <Form form={form} onFinish={id === 'create' ? handleAdd : handleUpdate}>   
                <Row gutter={24} style={{ display: 'flex', alignItems: 'flex-start'}}>
                    <Col span={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Card style={{ width: '100%'}}>
                            <h3>Settings</h3>
                                <Form.Item name="actionType" rules={[{ required: true, message: 'Please select the action type!' }]} label="Action Type" labelCol={{ span: 24 }}>
                                    <Select placeholder="Select Action Type" onChange={value => setSelectedActionType(value)}>
                                        {[...new Set(actionsTemplates.map(action => action.type))].map(type => (
                                            <Select.Option key={type} value={type}>
                                                {type}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                {selectedActionType !== 'Custom' && (
                                    <Form.Item name="templateName" rules={[{ required: true, message: 'Please select the template name!' }]} label="Template Name" labelCol={{ span: 24 }}>
                                        <Select placeholder="Select Template Name" onChange={value => {
                                            // Find the selected template
                                            const selectedTemplate = actionsTemplates.find(action => action.name === value);

                                            // Set the form values to the template values
                                            form.setFieldsValue(selectedTemplate);
                                        }}>
                                            {actionsTemplates.filter(action => action.type === selectedActionType).map(action => (
                                                <Select.Option key={action._id} value={action.name}>
                                                    {action.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )}
                                <Form.Item name="name" rules={[{ required: true, message: 'Please input the action name!' }]} label="Action Name" labelCol={{ span: 24 }}>
                                    <Input placeholder="Action Name" />
                                </Form.Item>
                                <Form.Item name="trigger" rules={[{ required: true, message: 'Please input the trigger!' }]} label="Trigger" labelCol={{ span: 24 }}>
                                    <Input.TextArea placeholder="Trigger" style={{ height: '20vh'}}/>
                                </Form.Item>
                                <div style={{ display: 'none', flexDirection: 'column', marginBottom: '20px'}}>
                                    {keywords.map((input, i) => (
                                        <Form.Item key={i} label={`Keyword ${i + 1}`} labelCol={{ span: 24 }}>
                                            <div style={{width: '100%', display: 'flex'}}>
                                                <Input
                                                    placeholder={`Keyword ${i + 1}`}
                                                    value={input.value || ""}
                                                    onChange={event => handleChangeKeyWord(i, event)}
                                                />
                                                <Button type="button" icon={<DeleteOutlined />} onClick={() => handleRemoveKeyWord(i)}/>
                                            </div>
                                        </Form.Item>
                                    ))}
                                    <Button onClick={handleAddKeyWord} outline="true">Add Keyword</Button>
                                </div>
                                <Form.Item>
                                    <Space style={{ width: '100%', justifyContent: 'end' }}>
                                        <Button type="primary" htmlType="submit">
                                            Save
                                        </Button>
                                        <Button onClick={() => navigate(`/assistants/${assistantId}`)} >
                                            Cancel
                                        </Button>
                                    </Space>
                                </Form.Item>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card style={{ width: '100%' }}>
                            <h3>Additional Settings</h3>
                            {/* <Form.Item name="jsonObject" rules={[{ required: true, message: 'Please input the JSON object!' }]} label="JSON Object" labelCol={{ span: 24 }}>
                                <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }}  />
                            </Form.Item> */}
                            {selectedActionType !== 'Email' && selectedActionType && (
                                <>
                                    <Form.Item name="jsonObject" rules={[{ required: true, message: 'Please input the JSON object!' }]} label="JSON Object" labelCol={{ span: 24 }} style={{display: 'flex', flexDirection: 'column'}}>
                                        <AceEditor
                                            style={{ width: '700px', height: '400px' }}
                                            mode="json"
                                            theme="monokai"
                                            name="jsonObject"
                                            onChange={value => {
                                                try {
                                                    const parsed = JSON.parse(value);
                                                    const beautified = JSON.stringify(parsed, null, 2);
                                                    form.setFieldsValue({ jsonObject: beautified });
                                                } catch (e) {
                                                    form.setFieldsValue({ jsonObject: value });
                                                }
                                            }}
                                            value={form.getFieldValue('jsonObject')}
                                            editorProps={{ $blockScrolling: true }}
                                            setOptions={{
                                                enableBasicAutocompletion: true,
                                                enableLiveAutocompletion: true,
                                                enableSnippets: true,
                                                showLineNumbers: true,
                                                tabSize: 2,
                                            }}
                                            workerPath="/static/js"
                                        />
                                    </Form.Item>
                                    <Form.Item name="requestType" rules={[{ required: true, message: 'Please select the request type!' }]} label="Request Type" labelCol={{ span: 24 }}>
                                        <Select placeholder="Select Request Type">
                                            <Select.Option value="GET">GET</Select.Option>
                                            <Select.Option value="POST">POST</Select.Option>
                                            <Select.Option value="PUT">PUT</Select.Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item name="webhookURL" rules={[{ required: true, message: 'Please input the webhook URL!' }]} label="Webhook URL" labelCol={{ span: 24 }}>
                                        <Input placeholder="Webhook URL" />
                                    </Form.Item>
                                </>
                            )}


                            {selectedActionType === 'Email' && (
                                <>
                                    <Form.Item name="to" rules={[{ required: true, message: 'Please input the To!' }]} label="To" labelCol={{ span: 24 }}>
                                        <Input placeholder="To" />
                                    </Form.Item>

                                    <Form.Item name="subject" rules={[{ required: true, message: 'Please input the subject!' }]} label="Subject" labelCol={{ span: 24 }}>
                                        <Input placeholder="Subject" />
                                    </Form.Item>

                                    <Form.Item name="jsonObject" rules={[{ required: true, message: 'Please input the body!' }]} label="Body" labelCol={{ span: 24 }}>
                                        <Input.TextArea placeholder="Body" rows={10}/>
                                    </Form.Item>
                                </>
                            )}

                        </Card>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default withAuth(CreateAction);