import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Card, Row, Col, Modal, List } from 'antd';
import { addApiKey, deleteApiKey, getApiSettings } from '../../api';
import cryptoRandomString from 'crypto-random-string'; // Make sure to install this package

const ApiPage = () => {
    const [apiKey, setApiKey] = useState('');
    // const [isEnabled, setIsEnabled] = useState(false);
    const [apiKeys, setApiKeys] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newKeyName, setNewKeyName] = useState('');
    const [isKeyGenerated, setIsKeyGenerated] = useState(false);


    useEffect(() => {
        // Retrieve the API settings when the component mounts
        getApiSettings()
            .then(response => {
                // Handle the response data
                if(response.data) {
                    const { apiKey, 
                        // isEnabled,
                         apiKeys } = response.data;
                    setApiKey(apiKey);
                    // setIsEnabled(isEnabled);
                    if (Array.isArray(apiKeys)) {
                        setApiKeys(apiKeys);
                    } else {
                        console.error('apiKeys is not an array:', apiKeys);
                    }
                }
            })
            .catch(error => {
                // Handle the error
                console.error('Error:', error);
                message.error('An error occurred while retrieving the settings.');
            });
    }, []);

    const handleGenerateKey = () => {
        const newKey = cryptoRandomString({length: 32, type: 'url-safe'});
        setApiKey(newKey); // Save the new key in the apiKey state
        setIsKeyGenerated(true);
    };
    
    const handleCopyKey = () => {
        navigator.clipboard.writeText(apiKey); // Copy the API key to the clipboard
        message.success('API key copied to clipboard!');
    };

    const handleAddKey = () => {
        addApiKey({name: newKeyName, key: apiKey})
            .then(response => {
                // Handle the response data
                console.log(response.data);
                setApiKeys([...apiKeys, {name: newKeyName, key: apiKey}]);
                setIsModalVisible(false);
                setIsKeyGenerated(false);
            })
            .catch(error => {
                // Handle the error
                console.error('Error:', error);
                message.error('An error occurred while adding the API key.');
            });
    };
    

    const handleDeleteKey = (keyId) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this key?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteApiKey(keyId)
                .then(response => {
                    // Handle the response data
                    console.log(response.data);
                    setApiKeys(apiKeys.filter(key => key._id !== keyId));
                })
                .catch(error => {
                    // Handle the error
                    console.error('Error:', error);
                    message.error('An error occurred while deleting the API key.');
                });
            },
        });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '50px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <h1 style={{ textAlign: 'left' }}>API Keys</h1>
                {localStorage.getItem('isAdmin') === 'true' ? (
                    <Button type="primary" onClick={() => setIsModalVisible(true)}>Add API Key</Button>
                ) : null}
            </div>
            <Row gutter={16}>
                <Col span={24}>
                    <Card>
                        <List
                            itemLayout="horizontal"
                            dataSource={apiKeys}
                            renderItem={item => (
                                <List.Item
                                    actions={[
                                        <Button danger onClick={() => handleDeleteKey(item._id)}>Delete</Button>
                                    ]}
                                >
                                    <List.Item.Meta
                                        title={item.name}
                                        description={`****-****-****-${item.key.slice(-4)}`}
                                    />
                                </List.Item>
                            )}
                        />
                    </Card>
                </Col>
            </Row>
            <Modal title="Add API Key" open={isModalVisible} onOk={handleAddKey} onCancel={() => { setIsModalVisible(false); setIsKeyGenerated(false); }} okButtonProps={{ disabled: !isKeyGenerated }}>
                <Form>
                    <Form.Item label="Key Name">
                        <Input onChange={e => setNewKeyName(e.target.value)} />
                    </Form.Item>
                    {isKeyGenerated ? (
                        <>
                            <Form.Item label="Your new API key">
                                <Input value={apiKey} readOnly />
                            </Form.Item>
                            <Form.Item>
                                <Button onClick={handleCopyKey}>Copy API Key</Button>
                            </Form.Item>
                        </>
                    ) : (
                        <Form.Item>
                            <Button type="primary" onClick={handleGenerateKey}>Generate API Key</Button>
                        </Form.Item>
                    )}
                </Form>
            </Modal>
        </div>
    );
};

export default ApiPage;