import React, { useEffect, useState } from 'react';
import withAuth from '../../withAuth';
import { useNavigate, useParams } from 'react-router-dom';
import { editAssistant, deleteAssistant, getAssistant, deleteAction, createAssistant } from '../../api';
import { Form, Input, Button, Card, notification, Space, Row, Col, List, Avatar, Modal, Select, Switch } from 'antd';
import { ThunderboltOutlined, EditOutlined, DeleteOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import ChatUI from './chatUI';


const CreateAssistant = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [form] = Form.useForm();
    const [assistant, setAssistant] = useState(null);
    // const modelOptions = ['gpt-4-turbo', 'gpt-4', 'Gemini', 'gpt-4o'];
    const modelOptions = ['gpt-4-turbo', 'gpt-4o'];
    // const languageOptions = ['English', 'Spanish'];
    const { Option } = Select;
    const [actions, setActions] = useState([]); // Add this line


    useEffect(() => {
        if(id === 'create'){
            // console.log(id)
            form.resetFields();
        } else {
            getAssistant(id)
                .then(assistant => {

                    form.setFieldsValue(assistant)
                    setAssistant(assistant);
                    setActions(assistant?.actions);
                    // console.log(assistant)
                })
                .catch(error => {
                    console.error('Error fetching assistant:', error);
                });
        }
    }, [id, form]);

    const handleAdd = values => {
        createAssistant(values)
        .then(response => {
            navigate(`/assistants/${response.data.assistantId}`);
        })
        .catch(error => {
            console.log(error);
            notification.error({
                message: 'Create Assistant Failed',
                description: 'There was an error while creating the assistant. Please try again.',
            });
        });
    };

    const handleUpdate = (values) => {
        // console.log(values)
        // const { name, instructions, model, language, temperature, webhookUrl, enabled } = values;
        editAssistant(id,values)
            .then(response => {
                // console.log(response)
                navigate('/assistants');
            })
            .catch(error => {
                console.log(error);
                notification.error({
                    message: 'Edit Assistant Failed',
                    description: 'There was an error while editing the assistant. Please try again.',
                });
            });
    };

    const handleDelete = () => {
        Modal.confirm({
            title: 'Are you sure you want to delete this Assistant?',
            content: 'This action cannot be undone. All actions associated with this assistant will also be deleted.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteAssistant(id)
                    .then(response => {
                        navigate('/assistants');
                    })
                    .catch(error => {
                        console.log(error);
                        notification.error({
                            message: 'Delete Assistant Failed',
                            description: 'There was an error while deleting the assistant. Please try again.',
                        });
                    });
                },
            });
    };

    const handleDeleteAction = (actionId) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this action?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteAction(actionId)
                    .then(response => {
                        setActions(actions.filter(action => action.id !== actionId));
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
        });
    }

    return (
        <div style={{ padding: '50px' }}>
        <Button type="link" onClick={() => navigate('/assistants')}>
            <ArrowLeftOutlined /> Go Back
        </Button>            
        <h2 style={{ textAlign: 'left' }}>
            {id === 'create' ? 'Create Assistant' : 'Update Assistant'}
        </h2>    
        <Row gutter={24} style={{ display: 'flex', alignItems: 'flex-start'}}>
            <Col xs={24} sm={24} md={24} lg={12} xl={8} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Card style={{width: '100%'}}>
                    <h3>Settings</h3>
                    <Form form={form} onFinish={id === 'create' ? handleAdd : handleUpdate}>
                        <Row gutter={16} style={{display: 'flex', alignItems: 'end'}}>
                            <Col style={{ display: 'flex', flex: 1}}>
                                <Form.Item name="name" rules={[{ required: true, message: 'Please input the assistant name!' }]} label="Assistant Name" labelCol={{ span: 24 }} style={{width: '100%'}}>
                                    <Input placeholder="Assistant Name" />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="enabled" valuePropName="checked" label="" labelCol={{ span: 24 }}>
                                    <Switch 
                                        checkedChildren="Enabled" 
                                        unCheckedChildren="Disabled" 
                                        // onChange={handleToggleAssistant}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>           
                        <Form.Item name="instructions" rules={[{ required: true, message: 'Please input the instructions!' }]} label="Instructions" labelCol={{ span: 24 }}>
                            <Input.TextArea placeholder="Instructions" style={{ height: '30vh' }} />
                        </Form.Item>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="model" rules={[{ required: true, message: 'Please select a model!' }]} label="Model" labelCol={{ span: 24 }}>
                                    <Select placeholder="Select a model">
                                        {modelOptions.map(option => <Option key={option} value={option}>{option}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="temperature" rules={[{ required: true, message: 'Please input the GPT temperature!' }]} label="GPT Temperature" labelCol={{ span: 24 }}>
                                    <Input type="float" placeholder="GPT Temperature" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item name="webhookUrl" label="Webhook URL" labelCol={{ span: 24 }}>
                            <Input placeholder="Message Webhook URL" />
                        </Form.Item>
                        <Form.Item>
                            <Space style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <Button type="primary" danger="true" icon={<DeleteOutlined />}  onClick={handleDelete}>
                                    Delete
                                </Button>
                                <div style={{display: 'flex'}}>
                                    <Button type="primary" htmlType="submit" style={{marginRight: '10px'}}>
                                        Save
                                    </Button>
                                    <Button onClick={() => navigate('/assistants')}>
                                        Cancel
                                    </Button>
                                </div>
                            </Space>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                <Card style={{width: '100%'}}>
                        <h3 style={{ textAlign: 'left' }}>Chat</h3>
 
                    <ChatUI assistant={assistant} />
                </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                <Card style={{width: '100%'}}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
                        <h3 style={{ textAlign: 'left' }}>Actions</h3>
                        <Button type="primary" style={{ marginBottom: 16 }} onClick={() => navigate(`/assistants/${id}/actions/create`)}>Add New Action</Button>
                    </div>
                    <List
                        itemLayout="horizontal"
                        dataSource={actions}
                        renderItem={action => (
                            <List.Item
                                actions={[
                                    <Button type="primary" icon={<EditOutlined />} onClick={() => navigate(`/assistants/${id}/actions/${action.id}`)}></Button>,
                                    <Button danger icon={<DeleteOutlined />} onClick={() => handleDeleteAction(action.id)}></Button>
                                ]}
                            >
                                <List.Item.Meta
                                    style={{ alignItems: 'center' }}
                                    avatar={<Avatar icon={<ThunderboltOutlined />}  style={{ background: '#f97d50', color: '#fff' }}/>}
                                    title={action.name}
                                    description={
                                        <>
                                            <div>{`id: ${action.id}`}</div>
                                            {/* <div>{`${action.trigger ? action.trigger : ''}`}</div> */}
                                        </>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </Card>
            </Col>
        </Row>
        </div>
    );
};

export default withAuth(CreateAssistant);