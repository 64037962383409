import '../App.css';
import withAuth from '../withAuth';
import React, { useState, useEffect, useRef } from 'react';
import { socket } from '../socket';
import { Layout, List, Input, Button, Avatar, /* Checkbox ,*/  Switch, Modal, message } from 'antd';
import { UserOutlined, SendOutlined, CalendarOutlined, RobotOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { getConversations, getMessages, updateConversation, deleteConversation } from '../api';
import ReactMarkdown from 'react-markdown';
import ActionModal from './actionModal';

const { Sider, Content } = Layout;

const Chat = () => {
    // State variables
    const [messages, setMessages] = useState([]);
    const [activeConversation, setActiveConversation] = useState(null);
    const [activeConversationId, setActiveConversationId] = useState(null);
    // const [selectedConversations, setSelectedConversations] = useState([]);
    const [conversations, setConversations] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const messagesEndRef = useRef(null);
    const [page, setPage] = useState(1);
    const [prevPage, setPrevPage] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [actionData, setActionData] = useState(null);

    // Fetch conversations when page changes
    useEffect(() => {
        if (prevPage === null || page !== prevPage) {
            getConversations(page)
                .then(response => {
                    if(response.data.length > 0){
                        const conversationProcessed = response.data.map(el => {
                            if(!el.assistant.enabled) {
                                el.assistantEnabled = false;
                            }
                            return el;
                        })
                        setConversations(prevConversations => [...prevConversations, ...conversationProcessed]);
                    }
                }).catch(error => {
                    console.error('Error:', error);
                });
            setPrevPage(page);
        }
    }, [page, prevPage]);

    // Set active conversation based on local storage or first conversation
    useEffect(() => {
        const storedConversationId = localStorage.getItem('storedConversationId');
        if (storedConversationId) {
            setActiveConversationId(JSON.parse(storedConversationId));
        } else if (conversations.length > 0) {
            setActiveConversationId(conversations[0]._id)
            localStorage.setItem('storedConversationId', JSON.stringify(conversations[0]._id));
        }
    }, [conversations]);

    // Fetch messages for active conversation
    useEffect(() => {
        if (activeConversationId) {
            getMessages(activeConversationId)
                .then(response => {
                    if(response.data){
                        setActiveConversation(response.data)
                    }
                    if(response.data.messages.length > 0) {
                        setMessages(response.data.messages);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, [activeConversationId]);

    // Join a room when the client connects to the server
    useEffect(() => {
        socket.emit('joinRoom', 'chat');
    }, []);

    const handleIconClick = (action) => {
        setActionData(action);
        setIsModalVisible(true);
    };

    // Listen for new messages from the socket
    useEffect(() => {
        socket.on('message', (message) => {
            if(message.conversationId === activeConversationId){
                setMessages((messages) => [...messages, message]);
            }
            if(!conversations.find(conversation => conversation._id === message.conversationId)){
                setConversations([...conversations, { _id: message.conversationId, name: message.name, assistant: { name: message.assistant.name }, assistantEnabled: message.assistantEnabled }]);
            } else {
                setConversations(conversations.map(conversation => {
                    if(conversation._id === message.conversationId){
                        conversation.unread = true;
                    }
                    return conversation;
                }));
            }


            if(message.conversationId === activeConversationId && !activeConversation.assistantEnabled){
                const msg = {
                    message: message.message,
                    role: message.role,
                    dateTime: new Date().toISOString()
                }
                activeConversation?.messages.push(msg)
            }
        });
        return () => {
            socket.off('message');
        };
    }, [activeConversationId, conversations, activeConversation]);

    // Listen for a new message
    socket.on('message', (msg) => {
        // console.log(msg)
        // Handle the new message
    });

    // Send a new message
    const handleSend = () => {
        const msg = {
            message: newMessage,
            role: "assistant",
            dateTime: new Date().toISOString()
        }
        if ( !activeConversation ) {
            message.error('Please Select a Conversation first');
            return;
        }
        activeConversation.messages.push(msg)

        updateConversation(activeConversation, activeConversation._id)
        socket.emit('message', activeConversation._id, 'chat'); // Include the room name when emitting the event
        setNewMessage('');
    };

    // Handle conversation click
    const handleConversationClick = (conversation) => {
        setConversations(conversations.map(c => {
            if(c._id === conversation._id){
                c.unread = false;
            }
            return c;
        }));
    };

    // Select all conversations
    // const handleSelectAll = (e) => {
    //     if (e.target.checked) {
    //         setSelectedConversations(conversations.map(conversation => conversation._id));
    //     } else {
    //         setSelectedConversations([]);
    //     }
    // };

    // Handle checkbox change
    // const handleCheckboxChange = (e, id) => {
    //     if (e.target.checked) {
    //         setSelectedConversations(prevSelectedConversations => [...prevSelectedConversations, id]);
    //     } else {
    //         setSelectedConversations(prevSelectedConversations => prevSelectedConversations.filter(conversationId => conversationId !== id));
    //     }
    // };

    // Delete a conversation
    const handleDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this Conversation?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteConversation(id)
                    .then(response => {
                        message.success('Conversation deleted successfully');
                        setConversations(conversations.filter(conversation => conversation._id !== id));

                    })
                    .catch(error => {
                        console.error('Error:', error);
                        message.error('Failed to delete Conversation');
                    });
            },
        });
    }

    // Toggle assistant
    const handleToggleAssistant = (e, conversation) => {
        console.log('Conversation', conversation)
        console.log('Conversations', conversations)
        const selectedConversation = conversations.filter(conversationArr => conversation._id === conversationArr._id);
        
        // Check if the assistant is enabled
        if (!selectedConversation[0].assistant.enabled) {
            message.error('The conversation assistant could not be enabled because the assistant is not enabled');
            return; // Exit the function
        }

        conversation.messages = activeConversation.messages
        conversation.assistantEnabled = e
        setActiveConversation(conversation)
        updateConversation(conversation, conversation._id)
    }

    // Scroll to the end of the messages list
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const parseMessageContent = (message) => {
        const parts = message.split(/(\*\*.*?\*\*)/g);
        return parts.map((part, index) => {
            if (part.startsWith('**') && part.endsWith('**')) {
                return <b key={index}>{part.slice(2, -2)}</b>;
            }
            return part;
        });
    };

    return (
        <Layout style={{ display: 'flex', flex: 1, height: 'calc(100vh - 50px)', backgroundColor: '#fff'}}>
            <Sider width={400} style={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'column', overflowY: 'auto', justifyContent: 'center' }}>
                {/* <div style={{padding: '10px', borderBottom: '1px solid #ebebeb', position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1}}>
                    <Checkbox 
                        style={{zIndex: 1}}
                        indeterminate={selectedConversations.length > 0 && selectedConversations.length < conversations.length}
                        onChange={handleSelectAll}
                        checked={selectedConversations.length === conversations.length}
                    >
                        Select All ({selectedConversations.length})
                    </Checkbox>  
                </div>          */}
                <List 
                style={{ 
                    padding: '10px', 
                    flexGrow: 1, // Make the list grow to take up the remaining space
                    overflowY: 'auto' // Enable vertical scrolling
                }}
                itemLayout="horizontal"
                dataSource={conversations}
                renderItem={conversation => (
                    <List.Item 
                        className={`conversation ${conversation.unread ? 'unread' : ''}`}
                        onClick={() => {
                            setActiveConversationId(conversation._id);
                            handleConversationClick(conversation);
                            localStorage.setItem('storedConversationId', JSON.stringify(conversation._id));
                        }}
                        style={{ 
                            cursor: 'pointer', 
                            display: 'flex',
                            backgroundColor: activeConversationId === conversation._id ? '#f0f0f0' : '#fff' // Changes the background color of the selected item
                        }}
                    >
                        {/* <Checkbox 
                            checked={selectedConversations.includes(conversation._id)}
                            onChange={(e) => handleCheckboxChange(e, conversation._id)}
                        /> */}
                        <List.Item.Meta
                            style={{ alignItems: 'center', padding: '0px 10px'}}
                            avatar={<Avatar icon={<UserOutlined />} />} // replace with the correct avatar URL
                            title={conversation.name}
                            description={conversation.assistant.name}
                        />
                            <Switch 
                                className={conversation.assistantEnabled ? "switch-enabled" : "switch-disabled"}
                                checkedChildren={<RobotOutlined />} 
                                unCheckedChildren={<UserOutlined />} 
                                checked={conversation.assistantEnabled}
                                onClick={(e) => { handleToggleAssistant(e, conversation); }}
                            />
                            <Button 
                                icon={<DeleteOutlined />} 
                                type="danger" 
                                shape="circle" 
                                size="small"
                                style={{ marginRight: '10px', marginLeft: '8px' }} 
                                onClick={(e) => { e.stopPropagation(); handleDelete(conversation._id); }} 
                            />
                    </List.Item>
                )}
            />
                <Button onClick={() => setPage(page + 1)} style={{ display: 'flex', justifyContent: 'center', width: '90%', margin: 'auto'}}>Load More</Button>

            </Sider>
            <Layout style={{display: 'flex', flex: 1}}>
                <Content style={{padding: '1px', flex: 1, height: '100%'}}> {/* Subtract the height of the top menu */}
                    <div style={{ display: 'flex', flex: 1, backgroundColor: '#fff', height: 'calc(100vh - 50px)', justifyContent: 'space-between', flexDirection: 'column' }}>
                        <div style={{ flex: 1, overflowY: 'auto', padding: '10px', backgroundColor: '#fafafa' }}>
                            <List
                                className='message-list-container'
                                itemLayout="horizontal"
                                dataSource={messages}
                                renderItem={message => (
                                    <List.Item className={`message ${message.role}`} style={{ border: 'none'}}>
                                        <div style={{marginRight: '10px'}} className={`chat-avatar ${message.role}`}>
                                            {message.role === 'assistant' ? <Avatar icon={<RobotOutlined/>} /> : <Avatar icon={<UserOutlined/>} />}
                                        </div>
                                        <div>
                                            {/* <p className="date">{new Date(message.dateTime).toLocaleString()}</p> */}
                                            {
                                                message.role !== 'action'  ?
                                                <>
                                                    <p className="message-content">
                                                    {typeof message.message === 'object' 
                                                        ? JSON.stringify(message.message) 
                                                        : parseMessageContent(message.message)
                                                    }
                                                    </p>
                                                    <p className="date">{ message.role === 'assistant' ?  `${message?.model} Tokens: ${message.usage?.total_tokens} `  : null }  <CalendarOutlined /> {new Date(message.dateTime).toLocaleString()}</p>
                                                </>
                                                : 
                                                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>                                                    
                                                    <p className="message-content">{message.message.message}</p>
                                                    <Button 
                                                        type="danger" 
                                                        shape="circle" 
                                                        icon={<InfoCircleOutlined />} 
                                                        onClick={() => handleIconClick(message.message.payload)} 
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </List.Item>
                                )}
                            />
                            <div ref={messagesEndRef} />
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 40px'}}>
                            <Input.TextArea
                                value={newMessage}
                                disabled={activeConversation?.assistantEnabled}
                                onChange={e => setNewMessage(e.target.value)}
                                onKeyDown={e => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();
                                        handleSend();
                                    }
                                }}
                                placeholder="Type a message"
                                style={{ height: '80px', fontSize: '18px', marginTop: '20px', marginBottom: '20px'}}
                            />
                            <Button type="primary" onClick={handleSend} style={{ height: '60px', fontSize: '18px', marginLeft: '5px' }} disabled={activeConversation?.assistantEnabled}>
                                <SendOutlined />
                            </Button>
                        </div>
                    </div>
                </Content>
            </Layout>

            <ActionModal 
                isVisible={isModalVisible} 
                handleOk={() => setIsModalVisible(false)} 
                handleCancel={() => setIsModalVisible(false)} 
                actionData={actionData} 
            />
        </Layout>
    );
};

export default withAuth(Chat);