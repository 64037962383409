import { useNavigate } from 'react-router-dom';
import { Menu, Avatar } from 'antd';
import { UserOutlined, LogoutOutlined, SettingOutlined, ApiOutlined, KeyOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { SubMenu } = Menu;

function UserMenu() {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (
        // <Menu mode="horizontal">
        <>
            {localStorage.getItem('isAdmin') === 'true' ? ( 
                <SubMenu key="SubMenu" icon={<Avatar icon={<SettingOutlined />} style={{ background: 'transparent' }}/>} theme='light'>
                    <Menu.Item key="users" icon={<UserOutlined />}>
                        <Link to="/users">Users</Link>
                    </Menu.Item>
                    <Menu.Item key="integrationApi" icon={<KeyOutlined />}>
                        <Link to="/integrations/api">API Keys</Link>
                    </Menu.Item>
                    <Menu.Item key="integrations" icon={<ApiOutlined />}>
                        <Link to="/integrations">Integrations</Link>
                    </Menu.Item>
                    <Menu.Item key="logout" icon={<LogoutOutlined />}>
                        <Link to="/logout" onClick={handleLogout}>Logout</Link>
                    </Menu.Item>
                </SubMenu>
                
            ) : 
                <SubMenu key="SubMenu" icon={<Avatar icon={<SettingOutlined />} style={{ background: 'transparent' }}/>} theme='light'>
                    <Menu.Item key="logout" icon={<LogoutOutlined />}>
                        <Link to="/logout" onClick={handleLogout}>Logout</Link>
                    </Menu.Item>
                </SubMenu>
            }
            </>
        // </Menu>
    );
}

export default UserMenu;