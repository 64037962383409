import React, { useEffect, useState } from 'react';
import withAuth from '../withAuth';
import { fetchDashboardData } from '../api'; // Import the fetchDashboardData function
import { DatePicker, Form, Layout, Card, Row, Col } from 'antd'; // Import Ant Design components
// import moment from 'moment';
import SimpleBarChart from './barchart';
import dayjs from 'dayjs'; // Import dayjs instead of moment
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'; // Import the plugin
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'; // Import the plugin

// Extend dayjs with the plugins
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);


const { Content } = Layout; 
// const { RangePicker } = DatePicker;
// const { Option } = Select;

const Dashboard = () => {
    const [data, setData] = useState([]);
    const dateType = 'month'; // 'year' or 'month
    const [dates, setDates] = useState(dayjs());

    useEffect(() => {
        const fetchData = async () => {
            
            const startDate = dates ? dates.startOf(dateType).format('YYYY-MM-DD') : dayjs().startOf(dateType).format('YYYY-MM-DD');
            const endDate = dates ? dates.endOf(dateType).format('YYYY-MM-DD') : dayjs().endOf(dateType).format('YYYY-MM-DD');
            const response  = await fetchDashboardData(startDate, endDate);
            // console.log('response', response);
            // console.log('Days in the selected month:', dates.daysInMonth());
            // setChartData(response);

            // Function to generate all dates in the current month
            function generateAllDatesForCurrentMonth() {
                const startOfMonth = dayjs().startOf('month');
                const endOfMonth = dayjs().endOf('month');
                const dates = [];

                let currentDate = startOfMonth;
                while (currentDate.isBefore(endOfMonth) || currentDate.isSame(endOfMonth, 'day')) {
                    dates.push(currentDate.format('YYYY-MM-DD'));
                    currentDate = currentDate.add(1, 'day');
                }

                return dates;
            }

            // Function to fill missing metrics for an assistant or action
            function fillMissingMetrics(metrics, allDates) {
                const filledMetrics = allDates.map(date => {
                    const existingMetric = metrics.find(m => m.date === date);
                    const day = dayjs(date).format('D');
                    const month = dayjs(date).format('MMM');
                    const label = `${month} ${day}`;


                    if (existingMetric) {
                        return { ...existingMetric, label }; // Add dayNumber to existing metrics
                    } else {
                        return { date, label, conversations: 0, assistantMessages: 0, userMessages: 0, count: 0 };
                    }
                });

                return filledMetrics;
            }

            // Helper function to summarize metrics for a given range of dates
            function summarizeMetrics(metrics, startDate, endDate) {
                const summary = { conversations: 0, assistantMessages: 0, userMessages: 0, count: 0 };
                metrics.forEach(metric => {
                    if (dayjs(metric.date).isSameOrAfter(startDate) && dayjs(metric.date).isSameOrBefore(endDate)) {
                        summary.conversations += metric.conversations;
                        summary.assistantMessages += metric.assistantMessages;
                        summary.userMessages += metric.userMessages;
                        summary.count += metric.count;
                    }
                });
                return summary;
            }

            // Modify the main function to include today's and month-to-date metrics
            function fillMetricsForCurrentMonth(assistants) {
                const allDates = generateAllDatesForCurrentMonth();
                const today = dayjs().format('YYYY-MM-DD');
                const startOfMonth = dayjs().startOf('month').format('YYYY-MM-DD');

                assistants.forEach(assistant => {
                    // Fill missing general metrics
                    assistant.metrics = fillMissingMetrics(assistant.metrics, allDates);

                    // Calculate and add today's metrics
                    assistant.todayMetrics = summarizeMetrics(assistant.metrics, today, today);

                    // Calculate and add month-to-date metrics
                    assistant.monthToDateMetrics = summarizeMetrics(assistant.metrics, startOfMonth, today);

                    // Fill missing action metrics and calculate today's and month-to-date metrics for each action
                    assistant.actions.forEach(action => {
                        action.metrics = fillMissingMetrics(action.metrics, allDates);
                        action.todayMetrics = summarizeMetrics(action.metrics, today, today);
                        action.monthToDateMetrics = summarizeMetrics(action.metrics, startOfMonth, today);
                    });
                });

                return assistants;
            }

            const filledAssistants = fillMetricsForCurrentMonth(response);
                
            setData(filledAssistants);
        };

        fetchData();
    }, [dateType, dates]); 

    const handleDatesChange = (dates) => {
        if (dates) {
            // Use dateType to determine the log message
            if (dateType === 'year') {
                console.log('Year:', dates.year());
            } else if (dateType === 'month') {
                console.log('Month Number:', dates.month() + 1); // month() is 0-indexed
            }
        }
        setDates(dates);
    };

    return (
        <Content style={{ padding: '50px' }}>
            <h1>Dashboard</h1>
            <Form layout="inline">
                {/* <Form.Item label="Date Type">
                    <Select defaultValue="month" style={{ width: 120 }} onChange={handleDateTypeChange}>
                        <Option value="year">Year</Option>
                        <Option value="month">Month</Option>
                    </Select>
                </Form.Item> */}
                {dateType === 'month' ? (
                    <Form.Item label="Select Month">
                        <DatePicker 
                            onChange={handleDatesChange} 
                            value={dates || dayjs()} // Use dayjs() here
                            picker="month" 
                            format="MMMM YYYY" 
                            disabledDate={current => current && current > dayjs().endOf('day')}
                            allowClear={false} // Disable clear button
                        />
                    </Form.Item>
                ) : (
                    <Form.Item label="Select Year">
                        <DatePicker 
                            onChange={handleDatesChange} 
                            value={dates || dayjs()} // Use dayjs() here
                            picker="year" 
                            disabledDate={current => current && current > dayjs().endOf('year')}
                            allowClear={false} // Disable clear button
                        />
                    </Form.Item>
                )}
            </Form>
            {data && (
                <>
                    {data.map((assistant, index) => (
                        <div key={index}>
                        <h2>{assistant.name}</h2>
                        <hr />
                        <Row gutter={16} style={{margin: '20px 0px'}}>
                            <React.Fragment key={index}>
                                <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                    <Card  title={
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <span>Conversations</span>
                                            <div>
                                                <span style={{ marginRight: '10px' }}> Today: {assistant.todayMetrics.conversations} </span>
                                                <span> MTD: {assistant.monthToDateMetrics.conversations} </span>
                                            </div>
                                        </div>
                                        }
                                    >
                                        <div style={{marginLeft: "-40px"}}>
                                            <SimpleBarChart data={assistant.metrics} width="100%" height={200} metric="conversations" color="#1677ff" />
                                        </div>
                                    </Card>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                    <Card title={
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <span>User Messages</span>
                                            <div>
                                                <span style={{ marginRight: '10px' }}> Today: {assistant.todayMetrics.userMessages} </span>
                                                <span> MTD: {assistant.monthToDateMetrics.userMessages} </span>
                                            </div>
                                        </div>
                                        }
                                    >
                                        <div style={{marginLeft: "-40px"}}>
                                            <SimpleBarChart data={assistant.metrics} width="100%" height={200} metric="userMessages" color="#1677ff" />
                                        </div>
                                    </Card>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                    <Card title={
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <span>Assistant Messages</span>
                                            <div>
                                                <span style={{ marginRight: '10px' }}> Today: {assistant.todayMetrics.assistantMessages} </span>
                                                <span> MTD: {assistant.monthToDateMetrics.assistantMessages} </span>
                                            </div>
                                        </div>
                                        }
                                    >
                                        <div style={{marginLeft: "-40px"}}>
                                            <SimpleBarChart data={assistant.metrics} width="100%" height={200} metric="assistantMessages" color="#1677ff" />
                                        </div>
                                    </Card>
                                </Col>
                            </React.Fragment>
                        </Row>
                        <Row gutter={16} style={{margin: '20px 0px'}}>
                            {assistant.actions && assistant.actions.map((action, index) => (
                                <Col xs={24} sm={24} md={24} lg={12} xl={8} key={index}>
                                    <Card title={                               
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <span>{action.name}</span>
                                            <div>
                                                <span style={{ marginRight: '10px' }}> Today: {action.todayMetrics.count} </span>
                                                <span> MTD: {action.monthToDateMetrics.count} </span>
                                            </div>
                                        </div>}>
                                        <div style={{marginLeft: "-40px"}}>
                                             <SimpleBarChart data={action.metrics} width="100%" height={200} metric="count" color="#ff8317" /> 
                                        </div>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        </div>
                    ))}
                </>
            )}
            <div>
            </div>
        </Content>
    );
};

export default withAuth(Dashboard);