import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const SimpleBarChart = ({ data, width, height, metric, color }) => {
    const dataTest = data;

    return (
        <ResponsiveContainer width={width} height={height}>
            <BarChart width={width} height={height} data={dataTest}> 
                <CartesianGrid strokeDasharray="0 0" vertical={false} opacity={0.2}/>
                <XAxis dataKey="label" interval={5} tickLine={false} axisLine={false}/>
                <YAxis tickLine={false} axisLine={false} allowDecimals={false}/>
                <Tooltip />
            <Bar dataKey={metric} fill={color} />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default SimpleBarChart;