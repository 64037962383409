// PrivateRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { DashboardOutlined, RobotOutlined, MessageOutlined } from '@ant-design/icons'; // Import the RobotOutlined icon from Ant Design
import { Link } from 'react-router-dom';
import UserMenu from './UserMenu';

function PrivateRoute({ children }) {
    const token = localStorage.getItem('token');
    const location = useLocation();

    if (!token) {
        return <Navigate to="/login" />;
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Menu mode="horizontal" style={{ display: 'flex', width: '100%', flex: 1}} selectedKeys={[location.pathname]} theme="dark" disabledOverflow={true}>
                    <Menu.Item key="/dashboard" icon={<DashboardOutlined />}>
                        <Link to="/dashboard">Dashboard</Link>
                    </Menu.Item>
                    <Menu.Item key="/assistants" icon={<RobotOutlined />}>
                        <Link to="/assistants">Assistants</Link>
                    </Menu.Item>
                    <Menu.Item key="/chat" icon={<MessageOutlined />}>
                        <Link to="/chat">Conversations</Link>
                    </Menu.Item>
                    <Menu.Item  style={{display: 'flex', flex: 1}} key="spacer"></Menu.Item>
                    <UserMenu/>
                </Menu>
            </div>
            {children}
        </div>
    );
}

export default PrivateRoute;