import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import Link
import { login } from './../api';
import { Form, Input, Button, Card, notification } from 'antd'; 

const Login = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token'); 

        if (token) {
            navigate('/dashboard'); 
        }
    }, [navigate]);

    const handleSubmit = (values) => {
        const { username, password } = values;
        login(username, password)
            .then(response => {
                console.log(response);
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('isAdmin', response.data.isAdmin);
                navigate('/dashboard');
            })
            .catch(error => {
                console.log(error);
                notification.error({
                    message: 'Login Failed',
                    description: error.response.data.message,
                });
            });
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Card style={{ width: 300 }}>
                <h1 style={{ textAlign: 'center' }}>Login</h1>
                <Form onFinish={handleSubmit}>
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input placeholder="Username" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                            Login
                        </Button>
                    </Form.Item>
                </Form>
                {/* <p style={{ textAlign: 'center' }}>
                    Don't have an account? <Link to="/signup">Register</Link>
                </p> */}
                <p style={{ textAlign: 'center' }}>
                    <Link to="/forgot-password">Forgot Password?</Link>
                </p>
            </Card>
        </div>
    );
};

export default Login;