// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminRoute from './AdminRoute';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
// import Signup from './components/Signup';
import Assistant from './components/assistants/Assistants';
import Chat from './components/Chat';
import PrivateRoute from './components/PrivateRoute';
import CreateUser from './components/users/Create';
import Users from './components/users/Users';
import EditUser from './components/users/Edit';
import CreateAssistant from './components/assistants/Create';
// import EditAssistant from './components/assistants/Edit';
import ActionsList from './components/actions/ActionsList';
import CreateAction from './components/actions/Create';
// import EditAction from './components/actions/Edit';
import APIPage from './components/integrations/ApiPage';
import ChatGPTPage from './components/integrations/ChatGpt';
import GeminiPage from './components/integrations/Gemini';
import WebhookPage from './components/integrations/WebHook';
import ChatWidgetPage from './components/chatWidgets/ChatWidget';
import CreateChatWidgetPage from './components/chatWidgets/Create';
import VerifyEmail from './components/VerifyEmail';
import EmailSent from './components/EmailSent';
import ForgotPassword from './components/ForgotPassword';
import PasswordResetEmailSent from './components/PasswordResetEmailSent';
import ResetPassword from './components/ResetPassword';
import NotAuthorized from './components/NotAuthorized';
import SugarCRMPage from './components/integrations/SugarCRM'
import IntegrationsPage from './components/integrations/IntegrationsPage'


// import HomePage from './components/front/HomePage'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/email-sent" element={<EmailSent />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/passord-reset-email-sent" element={<PasswordResetEmailSent />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/chat" element={<PrivateRoute><Chat /></PrivateRoute>} />
        <Route path="/assistants" element={<PrivateRoute><Assistant /></PrivateRoute>} />
        <Route path='/assistants/:id' element={<PrivateRoute><CreateAssistant /></PrivateRoute>} />
        {/* <Route path="/assistants/edit/:id" element={<PrivateRoute><EditAssistant /></PrivateRoute>} /> */}
        <Route path="/actions" element={<PrivateRoute><ActionsList /></PrivateRoute>} />
        {/* <Route path="/assistants/edit/:assistantId/actions/create" element={<PrivateRoute><CreateAction /></PrivateRoute>} /> */}
        <Route path="/assistants/:assistantId/actions/:id" element={<PrivateRoute><CreateAction /></PrivateRoute>} />
        {/* <Route path="/assistants/:assistantId/actions/edit/:id" element={<PrivateRoute><EditAction /></PrivateRoute>} />
        <Route path="/assistants/:assistantId/actions/edit/:id" element={<PrivateRoute><EditAction /></PrivateRoute>} /> */}
        {/* <Route path="/actions/edit/:id" element={<PrivateRoute><EditAction /></PrivateRoute>} /> */}
        <Route path="/users" element={<PrivateRoute><Users /></PrivateRoute>} />
        <Route path="/users/create" element={<PrivateRoute><AdminRoute><CreateUser /></AdminRoute></PrivateRoute>} />
        <Route path="/users/edit/:id" element={<PrivateRoute><AdminRoute><EditUser /></AdminRoute></PrivateRoute>} />       
        <Route path="/integrations/api" element={<PrivateRoute><AdminRoute><APIPage /></AdminRoute></PrivateRoute>} />       
        <Route path="/integrations/chatgpt" element={<PrivateRoute><AdminRoute><ChatGPTPage /></AdminRoute></PrivateRoute>} />
        <Route path="/integrations/gemini" element={<PrivateRoute><AdminRoute><GeminiPage /></AdminRoute></PrivateRoute>} />
        <Route path="/integrations/webhook" element={<PrivateRoute><AdminRoute><WebhookPage /></AdminRoute></PrivateRoute>} />
        <Route path="/integrations/chatwidget" element={<PrivateRoute><AdminRoute><ChatWidgetPage /></AdminRoute></PrivateRoute>} />
        <Route path="/integrations/sugarcrm" element={<PrivateRoute><AdminRoute><SugarCRMPage /></AdminRoute></PrivateRoute>} />
        {/* <Route path="/integrations/chatwidget/create" element={<PrivateRoute><AdminRoute><CreateChatWidgetPage /></AdminRoute></PrivateRoute>} /> */}
        <Route path="/integrations/chatwidget/:id" element={<PrivateRoute><AdminRoute><CreateChatWidgetPage /></AdminRoute></PrivateRoute>} />
        <Route path="/integrations" element={<PrivateRoute><AdminRoute><IntegrationsPage /></AdminRoute></PrivateRoute>} />
        <Route path="/not-authorized" element={<NotAuthorized />} />

        {/* <Route path="/" element={<HomePage />} /> */}
        <Route path="*" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
